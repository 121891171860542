
import { defineComponent, reactive } from 'vue';
import InputCheckbox from './InputCheckbox.vue';
import InputText from '@/components/Input/Text/InputText.vue';
import InputSelect from '@/components/Input/Select/InputSelect.vue';
import { mockProps } from './mockup';
import { sizes } from '@/models/components/size.model';

export default defineComponent({
  name: 'SampleInputCheckbox',
  components: {
    InputCheckbox,
    InputText,
    InputSelect,
  },
  setup() {
    return {
      props: reactive(mockProps),
      sizes,
    };
  },
});
